import React from "react"
import { Link, navigate } from "gatsby"

const BlogCard = ({ image, title, details, path }) => {
  const blogPostUri = `/blog/${path}`;
  return (
    <div className="cha-blog-card__container" onClick={()=> {navigate(blogPostUri)}}>
      {image && (
        <img className="cha-blog-card__container__img" src={image} alt={title} />
      )}
      <h3 className="cha-blog-card__container__title subhead3">{title}</h3>
      <p className="cha-blog-card__container__description body1">{details}</p>

      
      <Link 
        className="cha-blog-card__container__button"
        to={blogPostUri}
      >
        Read More
      </Link>
    </div>
  )
}

export default BlogCard
